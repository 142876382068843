import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const pageStyles = makeStyles({
  pageContainer: { 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh"
  },  
  sectionContainer: {
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.margin('10px'),
    ...shorthands.padding('15px'),
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: tokens.shadow4,
  }
})
