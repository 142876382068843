import { HubConnection } from "@microsoft/signalr";
import MD5 from "crypto-js/md5";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { WelcomeHints } from "../../components/WelcomeHints/WelcomeHints";
import { Conversation } from "../../models/Conversation";
import { Message, SenderType } from "../../models/Message";
import {
  GetStreamingConnection,
  SendFeedbackAsync,
  getConfigurationAsync,
} from "../../services/ChatService";
import { AppFeatureContext, appInitialContext } from "../../states/AppContext";
import { useAuth } from "../../states/AuthContext";
import {
  ChatFeatureContext,
  ChatFeatureContextHandler,
  initialContext as chatInitialContext,
  initialContextHandler,
} from "../../states/ChatContext";
import { pageStyles } from "../PageStyles";
import { ChatHistoryList } from "./components/ChatHistoryList/ChatHistoryList";
import { ChatInputBox } from "./components/ChatInputBox/ChatInputBox";
import {
  ToBackendAdditionalData,
  ToBackendMessage,
} from "./components/ChatMessage/ToBackendMessage";
import { getChatType } from "../../utils/localStorageUtils";
import { MastercamMessage } from "../../models/MastercamMessage";

export function ChatPage() {
  const styles = pageStyles();
  const [appContext, setAppContext] = useState(appInitialContext);
  const [context, setContext] = useState(chatInitialContext);
  const [contextHandler, setContextHandler] = useState(initialContextHandler);
  const [streamingConnection, setStreamingConnection] =
    useState<HubConnection | null>(null);
  const { isMastercam, token, username, logout } = useAuth();

  useEffect(() => {
    setContextHandler((h) => ({
      ...h,
      onSendMessage: onSendMessage,
      onSendFeedback: onSendFeedback,
      onRestartConversation: onRestartConversation,
    }));
  }, [context]);

  useEffect(() => {
    console.log("InitApplication");

    const getConfiguration = async () => {
      const configuration = await getConfigurationAsync();
      setAppContext((c) => ({ ...c, configuration: configuration }));
      initConversation();
    };

    getConfiguration();
  }, []);

  const initConversation = async () => {
    console.log("initConversation");
    const conversationId: string = uuid();
    const metadata = { userId: MD5(username!).toString() };
    const connection = GetStreamingConnection(token ?? "");
    // Start the SignalR connection
    connection
      .start()
      .then(() =>
        connection.invoke("ConnectToAgent", metadata.userId, conversationId)
      )
      .catch((err) => {
        console.error("SignalR connection error:", err);
        if (err.message.includes("401")) {
          logout();
        }
      });

    connection.onclose(async (error) => {
      console.log("Connection closed:", error);
      logout();
    });

    connection.onreconnecting(async () => {
      console.log("Connection lost, trying to reconnect...");
    });

    connection.onreconnected(() => {
      console.log("Reconnected to SignalR Hub");
    });

    console.log("SignalR connection established");
    setStreamingConnection(connection);

    // Register the ReceiveMessage event
    connection.on("ReceiveMessage", (message: Message) => {
      console.log("Received message [" + message + "]");
      if (message.sender === SenderType.FTFunction) {
        if (isMastercam && (window as any).chrome.webview) {
          const mastercamMessage = new MastercamMessage(message.text);
          (window as any).chrome.webview.postMessage(mastercamMessage);
        }
      }
      if (message.sender === SenderType.Notification) {
        const progressMessage = message.text;
        setContext((c) => ({
          ...c,
          isTakingTooLong: true,
          waitingMessage: progressMessage,
        }));
      } else if (message.sender === SenderType.LoadingNotification) {
        const progressMessage = message.text;
        setContext((c) => ({
          ...c,
          isLoading: true,
          isTakingTooLong: false,
          loadingMessage: progressMessage,
          conversation: {
            ...c.conversation,
            messages: [...c.conversation.messages],
          },
        }));
      } else {
        setContext((c) => ({
          ...c,
          isLoading: false,
          isTakingTooLong: false,
          conversation: {
            ...c.conversation,
            messages: [
              ...c.conversation.messages,
              { ...message, sender: message.sender },
            ],
          },
        }));
      }
    });

    setContext((c) => ({
      ...c,
      conversation: { id: conversationId, metadata, messages: [] },
    }));
  };

  const onSendMessage = useCallback(
    (messageText: string) => {
      console.log(
        "Send message [" +
          messageText +
          "] to conversation id [" +
          context.conversation.id +
          "]"
      );

      var additionalData: ToBackendAdditionalData | undefined = undefined;
      const chatType = getChatType();
      if (chatType) {
        additionalData = { chatType: chatType };
      }

      const sendMessage = async () => {
        const userMessage: ToBackendMessage = {
          id: uuid().toString(),
          conversationId: context.conversation.id,
          userId: context.conversation.metadata.userId,
          sender: SenderType.User,
          text: messageText,
          additionalData: additionalData,
          timestamp: new Date(),
          feedback: undefined,
          isError: false,
        };

        setContext((c) => ({
          ...c,
          isLoading: true,
          conversation: {
            ...c.conversation,
            messages: [...c.conversation.messages, userMessage],
          },
        }));

        if (streamingConnection?.state != "Connected") {
          logout();
        }
        streamingConnection?.send("ProcessMessage", userMessage);

        const waitTime = 20000;
        const waitingMessages =
          "Please wait while the agents are working for you... | Please wait while the agents are looking for a solution...";

        setTimeout(() => {
          const messages = waitingMessages.split("|");
          const messageToDisplay = `${
            messages[Math.floor(Math.random() * messages.length)]
          }`;
          setContext((c) => ({
            ...c,
            isTakingTooLong: true,
            waitingMessage: messageToDisplay,
          }));
        }, waitTime);
      };
      sendMessage().catch((err) => {
        console.error("SignalR send error:", err);
        if (err.message.includes("401")) {
          logout();
        }
      });
    },
    [context, streamingConnection]
  );

  function onSendFeedback(message: Message, feedback: -1 | 1) {
    console.log("onSendFeedback");

    const sendFeedbackAsync = async () =>
      await SendFeedbackAsync(
        context.conversation.id,
        message.id,
        feedback.toString(),
        message.text
      );

    sendFeedbackAsync();

    const conversation = context.conversation;
    const updatedConversation: Conversation = {
      ...conversation,
      messages: conversation.messages.map((m) =>
        m.id === message.id ? { ...m, feedback: feedback } : m
      ),
    };

    setContext((c) => ({ ...c, conversation: updatedConversation }));
  }

  const onRestartConversation = useCallback(async () => {
    if (streamingConnection) {
      try {
        const newConversationId = uuid();
        console.log(
          "Restart conversation with new id [" + newConversationId + "]"
        );

        await streamingConnection.invoke(
          "RestartConversation",
          context.conversation.metadata.userId,
          newConversationId
        );

        setContext((c) => ({
          ...c,
          conversation: {
            id: newConversationId,
            metadata: c.conversation.metadata,
            messages: [],
          },
        }));
      } catch (error) {
        console.error("Error restarting conversation: ", error);
      }
    }
  }, [streamingConnection, context]);

  return (
    <>
      <AppFeatureContext.Provider value={appContext}>
        {appContext.configuration?.chatConfiguration ? (
          <ChatFeatureContext.Provider value={context}>
            <ChatFeatureContextHandler.Provider value={contextHandler}>
              <div className={styles.sectionContainer}>
                {!context.conversation.messages ||
                context.conversation.messages.length === 0 ? (
                  <WelcomeHints />
                ) : (
                  <ChatHistoryList />
                )}
              </div>
              <div className={styles.sectionContainer}>
                <ChatInputBox />
              </div>
            </ChatFeatureContextHandler.Provider>
          </ChatFeatureContext.Provider>
        ) : (
          <></>
        )}
      </AppFeatureContext.Provider>
    </>
  );
}
