import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Title3,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import mastercamLogo from "../../assets/mastercam_white_red.svg";
import { useAuth } from "../../states/AuthContext";
import { LogoutOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  navBar: {
    backgroundColor: tokens.colorBrandBackground,
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...shorthands.padding("10px"),
  },
  title: {
    color: tokens.colorNeutralBackground1,
    display: "flex",
    alignItems: "center",
  },
  userMenu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "6px",
  },
  menuIcon: {
    height: "12px",
    marginRight: "6px",
    verticalAlign: "middle",
  },
  menuText: {
    fontSize: "12px",
    verticalAlign: "middle",
  },
});

export interface NavBarProps {
  userSignedIn: boolean;
}

export function NavBar() {
  const styles = useStyles();
  const { username, isAuthenticated, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={styles.navBar}>
      <Title3 className={styles.title}>
        <img
          src={mastercamLogo}
          alt="icon"
          style={{
            marginRight: "8px",
            height: "1.3em",
            width: "auto",
          }}
        />
        Copilot
      </Title3>
      {isAuthenticated && (
        <div className={styles.userMenu}>
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="primary">{username!}</MenuButton>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem onClick={handleLogout}>
                  <LogoutOutlined className={styles.menuIcon} />
                  <span className={styles.menuText}>Logout</span>
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
          <Avatar color="colorful" name={username!} />
        </div>
      )}
    </div>
  );
}
