import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import axios from 'axios';
import { Configuration } from '../models/Configuration';

const backendBaseUrl = import.meta.env.VITE_BACKEND_BASE_URL.endsWith('/')
  ? import.meta.env.VITE_BACKEND_BASE_URL
  : `${import.meta.env.VITE_BACKEND_BASE_URL}/`;

console.log(`Backend service URL: ${backendBaseUrl}`)

export async function getConfigurationAsync(): Promise<Configuration> {
  return {
    chatConfiguration: {
      welcomeTitle: "Let's start chatting...",
      welcomeSubtitle: "",
      welcomeHints: [
        "How do I open a file?",
        "How do I chamfer in titanium?",
      ],
      welcomeWarning: 'Warning: Answers are generated by AI and might be innacurate. Please review output carefully before use.',
      previewContent: 'Text',
    },
  }
}

export async function SendFeedbackAsync(
  conversationId: string,
  messageId: string,
  rating: string,
  replyMessage: string,
): Promise<void> {
  const request: SendFeedbackRequest = {
    conversationId,
    messageId,
    rating,
    replyMessage,
  }

  const url = new URL('api/chat/feedback', backendBaseUrl).href;
  await axios.post(url, request)
}

export function GetStreamingConnection(token: string): HubConnection {
  const url = new URL('geum-agent-hub', backendBaseUrl).href;
  console.log("Connecting to SignalR Hub at:", url);
  return new HubConnectionBuilder().withUrl(url, {
    accessTokenFactory: () => token,
    withCredentials: false,
  })
    .withServerTimeout(60000)
    .withKeepAliveInterval(30000)
    .withAutomaticReconnect([0, 2000, 4000])
    .build();
}

interface SendFeedbackRequest {
  conversationId: string
  messageId: string
  rating: string
  replyMessage: string
}