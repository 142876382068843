import {
  Button,
  Caption1,
  Divider,
  InputOnChangeData,
  Textarea,
  makeStyles,
} from "@fluentui/react-components";
import { AddSquareRegular, SendRegular } from "@fluentui/react-icons";
import React, { useContext, useState } from "react";
import { ChatFeatureContextHandler } from "../../../../states/ChatContext";
import "./ChatInputBox.css";
import { ChatSettingsBar } from "../ChatSettingsBar/ChatSettingsBar";

const useStyles = makeStyles({
  button: {
    marginRight: "5px",
  },
});

export function ChatInputBox() {
  const styles = useStyles();
  const contextHandler = useContext(ChatFeatureContextHandler);
  const [message, setMessage] = useState("");

  function onMessageChanged(
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    data?: InputOnChangeData
  ) {
    setMessage(data?.value || "");
  }

  function onEnterPress(event: React.KeyboardEvent<Element>) {
    if (event.key === "Enter" && !event.shiftKey) {
      onSendMessageClicked();
      event.preventDefault();
    }
  }

  function onSendMessageClicked() {
    if (message) {
      contextHandler.onSendMessage(message);
      setMessage("");
    }
  }

  return (
    <>
      <Textarea
        value={message}
        size="large"
        className="input-message"
        onChange={onMessageChanged}
        onKeyDown={onEnterPress}
      />

      <Divider style={{ marginTop: "5px" }}>
        <Caption1 className="message-disclaimer">
          This chat will produce AI-generated content. Check important info
        </Caption1>
      </Divider>
      <div className="input-toolbar">
        <div>
          <Button
            icon={<AddSquareRegular />}
            size="large"
            className={styles.button}
            onClick={contextHandler.onRestartConversation}
          >
            New topic
          </Button>
        </div>
        <ChatSettingsBar />

        <Button
          icon={<SendRegular />}
          appearance="primary"
          size="large"
          onClick={onSendMessageClicked}
          disabled={!message}
        ></Button>
      </div>
    </>
  );
}
