import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { MastercamMessage } from "../../../../models/MastercamMessage";
import { AdditionalData, Message } from "../../../../models/Message";
import { useAuth } from "../../../../states/AuthContext";
import "./ChatMessage.css";

export interface FtAlternative {
  score: string;
  label: string;
  name: string;
  short_description: string;
}

export interface FtFunctionAdditionalData extends AdditionalData {
  label: string;
  group: string;
  short_description: string;
  top_score: string;
  alternatives: string;
}

export interface FtFunctionMessage extends Message {
  additionalData: undefined | FtFunctionAdditionalData;
}

const FTButton = ({ parsedMessage }: { parsedMessage: FtFunctionMessage }) => {
  const { isMastercam } = useAuth();

  const alternatives: FtAlternative[] = JSON.parse(
    parsedMessage.additionalData?.alternatives || "[]"
  );

  const filteredAlternatives = alternatives.filter((alternative) => {
    return (
      parseFloat(alternative?.score || "0") >=
      parseFloat(parsedMessage.additionalData?.top_score || "0") -
        import.meta.env.VITE_FT_TOLERANCE
    );
  });

  const alternativesButtons = filteredAlternatives.map((alternative) => {
    return (
      <div key={alternative.name}>
        <Dialog>
          <DialogTrigger disableButtonEnhancement>
            <Button className="ft-button">{alternative.label}</Button>
          </DialogTrigger>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>{alternative.label}</DialogTitle>
              <DialogContent>{alternative.short_description}</DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">Close</Button>
                </DialogTrigger>
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    onClick={() =>
                      handleAlternativeButtonClick(alternative, isMastercam)
                    }
                    className="ft-button"
                    appearance="primary"
                  >
                    Execute
                  </Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </div>
    );
  });

  return (
    <div>
      <Button
        onClick={() => handleButtonClick(parsedMessage, isMastercam)}
        className="ft-button"
      >
        {parsedMessage.additionalData?.label || parsedMessage.text}
      </Button>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {parsedMessage.additionalData?.short_description || ""}
      </ReactMarkdown>
      {alternativesButtons.length > 0 && (
        <div>
          <h4>Alternatives</h4>
          {alternativesButtons}
        </div>
      )}
    </div>
  );
};

const handleAlternativeButtonClick = (
  alterative: FtAlternative,
  isMastercam: boolean
) => {
  if (isMastercam && (window as any).chrome.webview) {
    const mastercamMessage = new MastercamMessage(alterative.name);
    (window as any).chrome.webview.postMessage(mastercamMessage);
  }
};

const handleButtonClick = (parsedMessage: Message, isMastercam: boolean) => {
  if (isMastercam && (window as any).chrome.webview) {
    const mastercamMessage = new MastercamMessage(parsedMessage.text);
    (window as any).chrome.webview.postMessage(mastercamMessage);
  }
};

export default FTButton;
