import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./ChatMessage.css";
import { AdditionalData, Message } from "../../../../models/Message";
import { Caption1Strong } from "@fluentui/react-components";

export interface DocChatAdditionalData extends AdditionalData {
  citations: string;
}
export interface DocChatMessage extends Message {
  additionalData: undefined | DocChatAdditionalData;
}

type DocChatCitation = {
  original_filename: string;
  page_end: number;
  page_start: number;
  url: string;
  external_url: string;
};

// Function to decode base64 and parse JSON
const decodeBase64ToJson = (base64: string): DocChatCitation[] => {
  // Step 1: Decode the base64 string
  const jsonString = atob(base64);

  // Step 2: Parse the JSON string into a JavaScript object
  const citations: DocChatCitation[] = JSON.parse(jsonString);

  return citations;
};

// Function to group DocChatCitations by values of given key(s)
const groupBy = (
  array: DocChatCitation[],
  predicate: (
    value: DocChatCitation,
    index: number,
    array: DocChatCitation[]
  ) => string
) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: DocChatCitation[] });

const DocChat = ({ parsedMessage }: { parsedMessage: DocChatMessage }) => {
  if (parsedMessage.additionalData?.citations) {
    const base64String = parsedMessage.additionalData.citations;
    const citations: DocChatCitation[] = decodeBase64ToJson(base64String);

    // Group DocChatCitations by 'original_filename + external_url'
    const groupedCitations = groupBy(
      citations,
      ({ original_filename, external_url }) => original_filename + external_url
    );

    const newCitations = Object.values(groupedCitations).map((x) => {
      const pages = x
        .map((y) => {
          return {
            page_start: y.page_start,
            page_end: y.page_end,
            external_url: y.external_url,
          };
        })
        .sort((a, b) => a.page_start - b.page_start);
      if (pages[0].external_url) {
        return {
          original_filename: "web-link",
          url: pages[0].external_url,
          pages: [],
        };
      }
      return {
        original_filename: x[0].original_filename,
        url: x[0].url,
        pages: pages,
      };
    });

    return (
      <div>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {parsedMessage.text}
        </ReactMarkdown>

        {newCitations.length > 0 && (
          <>
            <Caption1Strong className="message-disclaimer">
              Citations
            </Caption1Strong>
            <br />
            <br />
          </>
        )}
        {newCitations.map((citation, index) => (
          <div key={index}>
            {citation.original_filename}
            <br />
            <a href={citation.url} target="_new">
              [{citation.url}]
            </a>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {parsedMessage.text}
      </ReactMarkdown>
    </div>
  );
};

export default DocChat;
