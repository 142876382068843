// Define the keys as constants for better maintainability
const LOCAL_STORAGE_KEYS = {
    CHAT_TYPE: 'chatType',
    USERNAME: 'username',
    TOKEN: 'token'
};

/**
 * Utility to set the chatType in localStorage.
 * @param value - The chat type to be stored.
 */
export const persistChatType = (value: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CHAT_TYPE, value);
};

/**
 * Utility to get the chatType from localStorage.
 * @returns The chat type, or null if not found.
 */
export const getChatType = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.CHAT_TYPE);
};

/**
 * Utility to remove the chatType from localStorage.
 */
export const removeChatType = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CHAT_TYPE);
};

/**
 * Utility to set the username in localStorage.
 * @param value - The username to be stored.
 */
export const persistUsername = (value: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.USERNAME, value);
};

/**
 * Utility to get the username from localStorage.
 * @returns The username, or null if not found.
 */
export const getUsername = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.USERNAME);
};

/**
 * Utility to remove the username from localStorage.
 */
export const removeUsername = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USERNAME);
};

/**
 * Utility to set the token in localStorage.
 * @param value - The token to be stored.
 */
export const persistToken = (value: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, value);
};

/**
 * Utility to get the token from localStorage.
 * @returns The token, or null if not found.
 */
export const getToken = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
};

/**
 * Utility to remove the token from localStorage.
 */
export const removeToken = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
};

/**
 * Utility to clear all specified keys from localStorage.
 */
export const clearAll = (): void => {
    removeChatType();
    removeUsername();
    removeToken();
};