import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../states/AuthContext";
import { pageStyles } from "../PageStyles";
import { Button, Input, Label, Title1 } from "@fluentui/react-components";
import { loginAsync } from "../../services/AuthService";
import "./Login.css";

const Login: React.FC = () => {
  const styles = pageStyles();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (username === "" || password === "") {
      setError("Please fill in all fields.");
    } else {
      const isMastercam = new URLSearchParams(window.location.search).has(
        "mastercam"
      );
      loginAsync(username, password)
        .then((token) => {
          console.log("Token:", token);
          if (token) {
            console.log(
              `Login successful, token: ${token}, isMastercam: ${isMastercam}`
            );

            login(username, token, isMastercam);
            navigate("/chat");
          } else {
            setError("Invalid username or password");
          }
        })
        .catch((err) => {
          console.error("An error occurred during login:", err);
          setError("An unexpected error occurred.");
        });
    }
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <div className="loginSectionContainer">
          <section>
            <Title1>Login</Title1>
            <form className="loginForm" onSubmit={handleSubmit}>
              <div className="inputRoot">
                <Label htmlFor="username" size="medium">
                  Username
                </Label>
                <Input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="inputDivider" />
              <div className="inputRoot">
                <Label htmlFor="password" size="medium">
                  Password
                </Label>
                <Input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <p className="loginError">{error}</p>
              <div className="buttonRoot">
                <Button type="submit">Login</Button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default Login;
