export interface AdditionalData {
  [key: string]: string;
}

export interface Message {
  id: string;
  conversationId: string;
  userId: string;
  sender: SenderType;
  text: string;
  additionalData: undefined | AdditionalData;
  timestamp: Date;
  feedback: undefined | -1 | 1;
  isError: boolean;
}

export enum SenderType {
  User = "User",
  Dispatcher = "Dispatcher",
  Notification = "Notification",
  FTFunction = "FTFunction",
  DocChat = "DocChat",

  ErrorNotification = "ErrorNotification",

  // Agent-specific notification types
  DispatcherNotification = "DispatcherNotification",
  LoadingNotification = "LoadingNotification",
}
