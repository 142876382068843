import { Tab, TabList, makeStyles } from "@fluentui/react-components";
import {
  DescriptionOutlined,
  SmartButtonOutlined,
  PsychologyAltOutlined,
  PostAddOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  getChatType,
  persistChatType,
} from "../../../../utils/localStorageUtils";

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
});

export function ChatSettingsBar() {
  const CHAT_TYPES = {
    ANALYSIS: "analysis",
    FT: "ft",
    DOC_CHAT: "docchat",
    BOTH: "both",
  };

  var storedChatType: string;
  useEffect(() => {
    storedChatType = getChatType() ?? CHAT_TYPES.ANALYSIS;
    setSelectedIcon(storedChatType);
  }, []);

  const [selectedIcon, setSelectedIcon] = useState("");

  const handleIconClick = (value: string) => {
    setSelectedIcon(value);
    persistChatType(value);
  };
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <TabList selectedValue={selectedIcon} size="small">
        <Tab
          value={CHAT_TYPES.ANALYSIS}
          icon={<PsychologyAltOutlined />}
          onClick={() => handleIconClick(CHAT_TYPES.ANALYSIS)}
        >
          Analyse Intent
        </Tab>
        <Tab
          value={CHAT_TYPES.FT}
          icon={<SmartButtonOutlined />}
          onClick={() => handleIconClick(CHAT_TYPES.FT)}
        >
          FT Execution
        </Tab>
        <Tab
          value={CHAT_TYPES.DOC_CHAT}
          icon={<DescriptionOutlined />}
          onClick={() => handleIconClick(CHAT_TYPES.DOC_CHAT)}
        >
          Documents
        </Tab>
        <Tab
          value={CHAT_TYPES.BOTH}
          icon={<PostAddOutlined />}
          onClick={() => handleIconClick(CHAT_TYPES.BOTH)}
        >
          Always Both
        </Tab>
      </TabList>
    </div>
  );
}
