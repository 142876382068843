import {
  FluentProvider,
  createLightTheme,
  Theme,
  BrandVariants,
} from "@fluentui/react-components";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import "./index.css";
import { validateEnv } from "./utils/environmentUtils.ts";
import { AuthProvider } from "./states/AuthContext.tsx";

// Validate environment variables
validateEnv();

const sandvikTheme: BrandVariants = {
  10: "#040400",
  20: "#1B1B00",
  30: "#2A2D0A",
  40: "#373917",
  50: "#434523",
  60: "#515230",
  70: "#242641",
  80: "#000000",
  90: "#7A795A",
  100: "#88876A",
  110: "#96957A",
  120: "#A5A48B",
  130: "#B3B29C",
  140: "#C2C1AF",
  150: "#D1D0C1",
  160: "#E0DFD5",
};

const lightTheme: Theme = {
  ...createLightTheme(sandvikTheme),
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <FluentProvider theme={lightTheme}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </FluentProvider>
  </React.StrictMode>
);
