
export const validateEnv = () => {
  const requiredEnvVars = ['VITE_BACKEND_BASE_URL', 
    'VITE_BACKEND_CLIENT_ID', 'VITE_AUTH_BASE_URL', 'VITE_FT_TOLERANCE'];

  requiredEnvVars.forEach((varName) => {
    if (!import.meta.env[varName]) {
      throw new Error(`Missing environment variable: ${varName}`);
    }
  });
};