import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { Navigate } from "react-router-dom";
import {
  clearAll,
  getToken,
  getUsername,
  persistToken,
  persistUsername,
} from "../utils/localStorageUtils";

interface AuthContextType {
  isAuthenticated: boolean;
  isMastercam: boolean;
  token: string | null;
  username: string | null;
  login: (username: string, token: string, isMastercam: boolean) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isMastercam, setIsMastercam] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);

  // Load authentication state from local storage on initial render
  useEffect(() => {
    const storedToken = getToken();
    const storedUsername = getUsername();

    if (storedToken && storedUsername) {
      setIsAuthenticated(true);
      setToken(storedToken);
      setUsername(storedUsername);
    }
  }, []);

  const login = (username: string, token: string, isMastercam: boolean) => {
    persistUsername(username);
    persistToken(token);

    setIsAuthenticated(true);
    setToken(token);
    setUsername(username);
    setIsMastercam(isMastercam);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setUsername(null);
    clearAll();
    <Navigate to="/" />;
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isMastercam, token, username, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
